import { createContext, ReactElement, useEffect, useState } from 'react';

export type ContentfulInitProps = {
  graphQLUrl: string;
  space: string;
  accessToken: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type QueryFunction = (query: string) => Promise<any>;

interface ContentfulContext {
  contentful: {
    graphQL: QueryFunction;
  };
  isAvailable: boolean;
}

export const ContentfulContext = createContext({
  contentful: {
    graphQL: () => undefined,
  },
  isAvailable: false,
} as ContentfulContext);

type MakeFetch = {
  graphQLUrl: string;
  space: string;
  accessToken: string;
};
const makeFetchContentful =
  ({ graphQLUrl, space, accessToken }: MakeFetch): QueryFunction =>
  async (query) => {
    if (!query) {
      return Promise.resolve({});
    }

    return fetch(`${graphQLUrl}${space}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ query }),
    })
      .then((response) => response.json())
      .then(({ errors, data }) => {
        if (errors) {
          return {};
        }
        return data;
      });
  };

export function ContentfulProvider({
  children,
  graphQLUrl,
  space,
  accessToken,
}: {
  children: JSX.Element;
  graphQLUrl: string;
  space: string;
  accessToken: string;
}): ReactElement<ContentfulContext> {
  const [graphQL, setGraphQL] = useState(() => undefined as QueryFunction);
  const [isAvailable, setIsAvailable] = useState(false);

  useEffect(() => {
    if (graphQLUrl && space && accessToken) {
      const graphQLClient = makeFetchContentful({
        graphQLUrl,
        space,
        accessToken,
      });

      if (graphQLClient) {
        setGraphQL(() => graphQLClient);
        setIsAvailable(true);
      }
    }
  }, [graphQLUrl, space, accessToken]);

  return (
    <ContentfulContext.Provider
      value={{
        isAvailable,
        contentful: { graphQL },
      }}
    >
      {children}
    </ContentfulContext.Provider>
  );
}

ContentfulProvider.displayName = 'ContentfulProvider';
export default ContentfulProvider;
