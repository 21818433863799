import { ContentfulInterstitialFragment } from './interstitial-fragment';

export const InterstitialPageQueryGQL = (id: string): string => `
  query {
    interstitialPage(id: "${id}") {
      ${ContentfulInterstitialFragment}
    }
  }
  
`;
