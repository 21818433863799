import { useUser } from '@auth0/nextjs-auth0/client';
import { isEmpty } from 'lodash';
import { useEffect } from 'react';

export function UserPageViewTracking(): JSX.Element {
  const { user } = useUser();

  useEffect(() => {
    if (!isEmpty(user)) {
      // segment tracking
      if (window.analytics?.identify) {
        window.analytics.identify(user.userId, {
          id: user.userId,
          user_id: user.userId,
          phone: user.phoneNumber,
          firstName: user.firstName,
          lastName: user.lastName,
          state: user.state,
          email: user.email,
        });
      }
    }
  }, [user]);

  return <></>;
}

export default UserPageViewTracking;
