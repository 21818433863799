import { Box, Container, ContainerProps } from '@mui/material';
import { useUser } from '@auth0/nextjs-auth0/client';
import { useEffect } from 'react';
import GlobalContentProvider from 'apps/care/components/context/global-content-provider';
import { UserPageViewTracking } from './user-pageview-tracking';
import { AppConfig } from '../../types/app-config';
import { Flags } from '../../types/flags';
import ContentfulProvider, {
  ContentfulInitProps,
} from '../context/contentful-provider';

export function Layout({
  header = <></>,
  children,
  config,
  maxWidth,
  updateConfig,
}: {
  header?: React.ReactNode;
  children?: React.ReactNode;
  config?: AppConfig;
  contentful: ContentfulInitProps;
  flags?: Flags;
  maxWidth?: ContainerProps['maxWidth'];
  updateConfig?: () => Promise<void>;
}): JSX.Element {
  const { user } = useUser();

  useEffect(
    () => {
      if (!user) {
        // We can rely on the initial app initializer for anonymous
        return;
      }
      updateConfig();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user],
  );

  const customSX =
    maxWidth === 'xm'
      ? {
          '@media (min-width: 830px)': {
            maxWidth: '830px',
          },
        }
      : {};

  return (
    <ContentfulProvider
      graphQLUrl={config?.contentful?.graphQLUrl}
      space={config?.contentful?.space}
      accessToken={config?.contentful?.accessToken}
    >
      <GlobalContentProvider>
        <>
          <Box
            sx={(theme): object => ({
              backgroundColor: 'var(--mui-sunrise-light-surface-main)',
              color: 'var(--mui-sunrise-light-surface-onMain)',
              minHeight: '100vh',
              paddingBottom: theme.spacing(12),
            })}
          >
            <UserPageViewTracking />
            {header}
            <Container
              maxWidth={maxWidth === 'xm' ? false : 'sm'}
              sx={{
                marginTop: 2.5,
                ...customSX,
              }}
            >
              {children}
            </Container>
          </Box>
        </>
      </GlobalContentProvider>
    </ContentfulProvider>
  );
}

export default Layout;
