import { isEmpty } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import { ContentfulContext } from '../../components/context/contentful-provider';

export function useContentfulQuery(gql: string): {
  isLoading: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
} {
  const { contentful, isAvailable } = useContext(ContentfulContext);
  const [isLoading, setIsLoading] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [data, setData] = useState(undefined as any);

  useEffect(() => {
    if (isAvailable && gql) {
      const fetchContent = async (): Promise<void> => {
        const data = await contentful.graphQL(gql);

        if (!isEmpty(data)) {
          setData(data);
        }

        setIsLoading(false);
      };

      fetchContent();
    }
  }, [contentful, isAvailable, gql]);

  return {
    data,
    isLoading,
  };
}
