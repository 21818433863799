export const GlobalContentQueryGQL = (id: string): string => `
query {
  globalContent(id:"${id}") {
    itemsCollection {
      items {
        internalName
        key
        value
      }
    }
  }
}`;
