import { createContext, ReactElement, useMemo } from 'react';
import { useContentfulQuery } from '../../queries/contentful/use-contentful-query';
import { GlobalContentQueryGQL } from './global-content-query.gql';
import { ContentKeys, defaultGlobalContent } from './default-global-content';

export type ContentfulInitProps = {
  graphQLUrl: string;
  space: string;
  accessToken: string;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type QueryFunction = (query: string) => Promise<any>;

interface GlobalContentItem {
  [key: string]: string;
}

interface GlobalContentContext {
  content: GlobalContentItem;
  lookupContent: (key: string) => string;
  isLoading: boolean;
}

export const GlobalContentContext = createContext({
  content: {} as GlobalContentItem,
  lookupContent: (key: string) => key,
  isLoading: false,
} as GlobalContentContext);

export function GlobalContentProvider({
  children,
}: {
  children: JSX.Element;
}): ReactElement<GlobalContentContext> {
  const { data, isLoading } = useContentfulQuery(
    GlobalContentQueryGQL('7c6J47RhEwGXEHOXRyfSAC'),
  );
  const content = useMemo(() => {
    const updatedContent = {} as GlobalContentItem;

    data?.globalContent?.itemsCollection?.items.forEach((item) => {
      updatedContent[item.key as ContentKeys] = item.value;
    });
    return updatedContent;
  }, [data]);

  const lookupContent = (key: ContentKeys): string => {
    if (isLoading) {
      return '';
    }

    if (content && content[key]) {
      return content[key];
    }

    if (defaultGlobalContent[key]) {
      return defaultGlobalContent[key];
    }

    return `Missing content for key: ${key}`;
  };

  return (
    <GlobalContentContext.Provider
      value={{
        content: content ?? {},
        lookupContent,
        isLoading,
      }}
    >
      {children}
    </GlobalContentContext.Provider>
  );
}

GlobalContentProvider.displayName = 'GlobalContentProvider';
export default GlobalContentProvider;
